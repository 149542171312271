import request from '@index/utils/request'

export function handleMatchdetails(id) { //比赛详情
  return request({
    url: 'matchinfo/details',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleStreamlineDetails(id) { //比赛详情
  return request({
    url: 'matchinfo/streamlineDetails',
    method: 'post',
    params: {
      id: id
    }
  })
}


export function handleQueryMatchRegulationsByMatchId(matchId) {// 获取赛事规程
  return request({
    url: 'matchinfo/queryMatchRegulationsByMatchId',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}