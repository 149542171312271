import request from '@index/utils/request'


export function handlePageQueryMatchArrangeTable(data) { //分页查询赛程安排表
	return request({
		url: 'matcharrangetable/pageQueryMatchArrangeTable',
		method: 'post',
		data
	})
}


export function handleDeleteMatchArrangeTable(matchId) { //删除赛程安排表
	return request({
		url: 'matcharrangetable/deleteMatchArrangeTable',
		method: 'post',
		params: {
			matchId: matchId
		}
	})
}



export function handleMatchArrangeTableDownload(matchId) { //删除赛程安排表 
	return request({
		url: 'matcharrangetable/download',
		method: 'post',
		responseType: 'blob',
		params: {
			matchId: matchId
		}
	})
}